import React from 'react';

export const FramiContext = React.createContext({
    currentFrami: null,
    isFramiObsolete: false,
    memoizedMaterials: {},
    memoizedCategories: {},
    memoizedFrameSizes: {},
    memoizedHorizontalFrameSizes: {},
    memoizedVerticalFrameSizes: {},
    memoizedSquareFrameSizes: {},
    memoizedPapers: [],
    memoizedCardboardPassepartouts: {},
    memoizedPredefinedFrameStyles: {},
    memoizedImageFilters: [],
    obsoleteFrameMaterialIds: ['WH', 'CMK', 'DIGITAL', 'NF'],
    openLoginModal: false,
    applyCoupon: () => {},
    createFrami: () => {},
    removeCoupon: () => {},
    setCurrentFrami: () => {},
    updateFramiMaterial: () => {},
    updateFramiCardboardPassepartout: () => {},
    updateFramiLUTFilter: () => {},
    updateFramiWallColor: () => {},
    updateFramiWallShape: () => {},
    updateFramiStairsWallShape: () => {},
    updateFramiHangingSystem: () => {},
    updateFramiPaper: () => {},
    updateFramiPredfinedFrameStyle: () => {},
    fetchFrami: () => {},
    getFrameSizeAndMaterial: () => {},
    getMinFrameSizeWidth: () => {},
    getMinFrameSizeHeight: () => {},
    getMaxFrameSizeWidth: () => {},
    getMaxFrameSizeHeight: () => {},
    setOpenLoginModal: () => {},
});

export function useFrami() {
    return React.useContext(FramiContext);
}
