import React, { createContext, useContext } from 'react';

export const PriceContext = createContext({
    autoDiscount: {},
    baseHangerPrice: 0,
    baseFramePrice: 0,
    currencyRates: {},
    deliveryCosts: {},
    createComposition: (template) => {},
    assignMaterialsToFrames: (frameStyle, frames) => {},
    calculateCompositionPrices: (composition, selectedPaper = null, order = null) => {},
    calculateTemplatePrice: (
        index,
        profilePricePerMPLN = 15,
        paperPricePerM2PLN = 200,
        hanging_system = 'F',
        passepartoutPricePerM2PLN = 0,
    ) => {},
    getAutoDiscountPercentage: (price) => {},
    getCompositionFramePrice: (frames, targetMaterial) => {},
    getCompositionHangersPrice: (frameCount, hangingSystem) => {},
    getCompositionPassepartoutPrice: (frames, defaultPassepartout = null, targetPassepartoutType = null) => {},
    getCompositionPrintPrice: (frameAreaOrFrames, paper) => {},
    getSingleFrameAndPrintPrice: (frameSize, frameMaterial, paper) => {},
    getPricePLN: (amount, currency) => {},
    getCouponDiscountPrice: (amount, order = null) => {},
    formatPrice: (amount, round = true) => {},
    getBruttoPrice: (price) => {}
});

export function usePrice() {
    return useContext(PriceContext);
}
