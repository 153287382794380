import React, { createContext, useContext } from 'react';

export const AuthContext = createContext({
    user: null,
    eventsCounter: {
        composition_select: 0,
        photo_upload: 0,
        ready_to_buy: 0,
        add_to_cart: 0,
        payment: 0,
    },
    marketingAccepted: false,
    isFeatureFlagAvailable: false,
    setEventsCounter: () => {},
    logout: () => {},
    login: () => {},
    reloadUser: () => {},
    updateGoogleEventsCounter: () => {},
});

export function useAuth() {
    return useContext(AuthContext);
}
