import React from "react";

export const ImagesContext = React.createContext({
    images: [],
    selectedImages: [],
    imagesLoading: false,
    uploadProgress: null,
    baselineImages: [],
    setSelectedImages: () => {},
    addImage: () => {},
    deleteImage: () => {},
    fetchImages: () => {},
    setImageProps: () => {},
    setUploadProgress: () => {},
    setImageCroppingCenter: () => {},
    determineThumbnailSize: () => {},
    setBaselineImages: () => {},
});

export function useImages() {
    return React.useContext(ImagesContext);
}
