import React from 'react';

export const DrawerContext = React.createContext({
    setCanScroll: () => {},
    scrollRef: { current: null },
    setDrawerTitle: () => {},
    setDrawerOnClose: () => {},
    setHideButtons: () => {},
});

export function useDrawer() {
    return React.useContext(DrawerContext);
}
