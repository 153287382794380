import React, { createContext, useContext } from 'react';

export const NavigationContext = createContext({
    clickMode: 'zoom',
    frameStyleMode: 'basic',
    setFrameStyleMode: () => {},
    dragMode: false,
    editMode: false,
    setEditMode: () => {},
    floatingBottomContent: null,
    setFloatingBottomContent: () => {},
    floatingBottomHeight: 0,
    setFloatingBottomHeight: () => {},

    openModelViewer: false,
    setOpenModelViewer: () => {},

    drawerContent: null,
    setDrawerContent: () => {},
    drawerHeight: 0,
    setDrawerHeight: () => {},

    navbarContent: null,
    setNavbarContent: () => {},
    navbarHeight: 0,
    setNavbarHeight: () => {},

    toolbarHeight: 0,
    setToolbarHeight: () => {},

    drawerStack: [],
    activeDrawer: null,
    drawerHeaderHeight: 0,
    setDrawerHeaderHeight: () => {},
    openDrawer: () => {},
    closeDrawer: () => {},
    resetDrawerQueue: () => {},

    activeHint: 1,
    setActiveHint: () => {},
    widthInputFocus: false,
    heightInputFocus: false,
    setWidthInputFocus: () => {},
    setHeightInputFocus: () => {},
});

export function useNavigation() {
    return useContext(NavigationContext);
}
