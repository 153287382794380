import React, { createContext, useContext } from 'react';

export const CheckoutContext = createContext({
    activeStep: 0,
    hangersOptions: [],
    paperOptions: [],
    selectedPaper: null,
    selectedHangers: null,
    ShippingInfoSchema: null,
    stepsLabels: null,
    templateSelected: null,
    beginCheckout: () => {},
    setActiveStep: () => {},
    setTemplateSelected: () => {},
    validateFullAddress: (values, isBillingField = false) => {},
});

export function useCheckout() {
    return useContext(CheckoutContext);
}
