import { updateCrop } from "./composition"
import { generateRandomId } from "./helpers"


export const passepartoutWidths = [20, 30, 50, 70, 100, 130, 170];
export const passepartoutFactors = {"None": 0, "Narrow": 0.08, "Standard": 0.14, "Wide": 0.20, "Extra": 0.26};

export function createFrame(
    currentComp,
    frameSize,
    frameMaterial,
    frameImage,
    imageFilter,
    passepartoutWidth,
    x,
    y,
    params
) {
    if (x == null || y == null) {
        x = Math.round(currentComp.width / 2 - frameSize.width / 2)
        y = Math.round(currentComp.height / 2 + frameSize.height / 2)
    }

    if (passepartoutWidth == null) {
        passepartoutWidth = 'None';
    }

    let frame = {
        id: generateRandomId(),
        x: x,
        y: y,
        frame_top: currentComp.height - y,
        frame_left: x,
        frame_material: frameMaterial.id,
        frame_size: frameSize.id,
        image: frameImage ? frameImage.filename : null,
        cropping: "0,0,1,1",
        filter: imageFilter,
        passepartout_width_mm: getPassepartoutBestFit(
            passepartoutWidth,
            frameSize.width - (2 * frameMaterial.face_width),
            frameSize.height - (2 * frameMaterial.face_width)
        ),
        calculated: {
            image_top: currentComp.height - y + frameMaterial.face_width,
            image_left: x + frameMaterial.face_width,
            image_width: frameSize.width - (2 * frameMaterial.face_width),
            image_height: frameSize.height - (2 * frameMaterial.face_width),
            frame_width: frameSize.width,
            frame_height: frameSize.height,
            dpi: 0,
            crop_zoom: 1,
            crop_zoom_min: 1,
        }
    }

    if (params) {
        frame = { ...frame, ...params }
    }

    if (frameImage) {
        frame = { ...frame, cropping: updateCrop(frameImage, frame, true) }
        frame = {
            ...frame, calculated: { ...frame.calculated, dpi: calculateDpi(frame, frameMaterial.face_width) }
        }
    }

        return frame;
}

function getCoordinateTuple(frame, faceWidth) {
    let baseX = frame.x;
    let baseY = frame.y;

    let offsetX = faceWidth;
    let offsetY = -offsetX;

    return [baseX + offsetX, baseY + offsetY];
}

export function calculateCropZoomLimit(frame, image, faceWidth, frameSize) {
    if (!frame || !image) {
        return 1;
    }
    const passepartout = frame.passepartout_width_mm;

    const frameWidth = frameSize ? frameSize.width : frame.calculated.frame_width;
    const frameHeight = frameSize ? frameSize.height : frame.calculated.frame_height;

    const imageWidth = frameWidth - faceWidth * 2 - 2 * passepartout
    const imageHeight = frameHeight - faceWidth * 2 - 2 * passepartout
    const originalWidth = image.width;
    const originalHeight = image.height;
    const aspectRatio = imageWidth / imageHeight;

    let cropWidth = originalWidth
    let cropHeight = originalWidth / aspectRatio
    if (cropHeight > originalHeight) {
        cropWidth = originalHeight * aspectRatio
        cropHeight = originalHeight
    }
    return Math.max(originalWidth / cropWidth, originalHeight / cropHeight)
}

export function calculateCropZoom(frame, image) {
    if (!frame || !image) {
        return 1;
    }
    const originalWidth = image.width;
    const originalHeight = image.height;
    let crop;
    if (frame.cropping) {
        crop = frame.cropping.split(",").map(Number);
    } else {
        crop = [0, 0, 1, 1];
    }
    const cropWidth = crop[2] - crop[0];
    const cropHeight = crop[3] - crop[1];
    const cropScaleX = originalWidth / cropWidth;
    const cropScaleY = originalHeight / cropHeight;
    return Math.max(cropScaleX, cropScaleY);
}

export function calculateDpi(frame, faceWidth, frameSize) {
    let passepartout;
    if (frame.passepartout_width_mm) {
        passepartout = frame.passepartout_width_mm;
    } else {
        passepartout = 0;
    }

    let crop;
    if (frame.cropping) {
        crop = frame.cropping.split(",").map(Number);
    } else {
        crop = [0, 0, 1, 1];
    }

    const frameWidth = frameSize ? frameSize.width : frame.calculated.frame_width;
    const frameHeight = frameSize ? frameSize.height : frame.calculated.frame_height;

    const w = frameWidth - (2 * (faceWidth - 4.5 + 2)) - (2 * passepartout);
    const h = frameHeight - (2 * (faceWidth - 4.5 + 2)) - (2 * passepartout);

    const w_px = crop[2] - crop[0];
    const h_px = crop[3] - crop[1];

    return Math.round(Math.min(w_px / (w / 25.4), h_px / (h / 25.4)));
}

export function calculateFrameImageProps(frame, frameImage, frameSize, faceWidth, compHeight) {
    const [x, y] = getCoordinateTuple(frame, faceWidth);
    const cropZoomLimit = calculateCropZoomLimit(frame, frameImage, faceWidth, frameSize);

    if (!frameSize) {
        return {
            frame_width: 0,
            frame_height: 0,
            image_top: 0,
            image_left: 0,
            image_width: 0,
            image_height: 0,
            dpi: 0,
            crop_zoom: 0,
            crop_zoom_min: 0,
        }   
    }

    return {
        frame_width: frameSize.width,
        frame_height: frameSize.height,
        image_top: compHeight - y,
        image_left: x,
        image_width: frameSize.width - faceWidth * 2,
        image_height: frameSize.height - faceWidth * 2,
        dpi: calculateDpi(frame, faceWidth, frameSize),
        crop_zoom: Math.max(calculateCropZoom(frame, frameImage), cropZoomLimit),
        crop_zoom_min: cropZoomLimit,
    };
}

export function checkFramePassepartoutLimits(passepartoutWidth, imageWidth, imageHeight, factor = 3) {
    let calculatedPassepartout = passepartoutWidth;
    let reducedPassepartout = false;
    if (passepartoutWidth > Math.min(imageWidth, imageHeight) / factor) {
        calculatedPassepartout = passepartoutWidths.slice().reverse().find((el) => el < Math.min(
            imageWidth, imageHeight) / factor
        );
        reducedPassepartout = true;
    }
    return [calculatedPassepartout, reducedPassepartout];
}

export function getPassepartoutBestFit(passepartoutWidth, imageWidth, imageHeight) {
    if (passepartoutWidth === "None") return 0;
    let factor = passepartoutFactors[passepartoutWidth];
    if (factor === undefined) {
        factor = passepartoutFactors["Standard"];
    }

    let calculatedPassepartout = passepartoutWidths.reduce((prev, curr) => {
        return (Math.abs(
            curr - factor * Math.max(imageWidth, imageHeight)
        ) < Math.abs(prev - factor * Math.max(imageWidth, imageHeight)) ? curr : prev);
    });
    let reducedPassepartout;

    [calculatedPassepartout, reducedPassepartout] = checkFramePassepartoutLimits(
        calculatedPassepartout, imageWidth, imageHeight
    );

    return calculatedPassepartout;
}

export function getFramePassepartoutWidthType(passepartoutWidth, imageWidth, imageHeight) {
    return Object.keys(passepartoutFactors).filter((el) => passepartoutWidth === getPassepartoutBestFit(
        el, imageWidth, imageHeight
    ));
}

export const getPassepartoutOptions = (frame) => {
    const imageWidth = frame.calculated.image_width;
    const imageHeight = frame.calculated.image_height;
    const [maxPassepartoutWidth, _] = checkFramePassepartoutLimits(
        Math.max(...passepartoutWidths), imageWidth, imageHeight
    );
    return passepartoutWidths.filter((width) => width <= maxPassepartoutWidth);
}

export const getNormalizedFrameSize = (frame) => {
    const [frameSize] = frame.frame_size.match(/\d{2,3}x\d{2,3}/);
    return frameSize;
}
