import React from 'react';
import styled from 'styled-components';


export const LoaderWrapper = styled.section`
    display: ${(props) => (props.$hide ? 'none' : 'flex')};
    justify-content: center;
    align-items: ${(props) => (props.top ? 'start' : 'center')};
    width: ${(props) => ((props.$isMobile || props.$overlap) ? '100vw' : '')};
    height: ${(props) => (props.$overlap ? '100vh' : '100%')};
    min-height: 0;
    position: ${(props) => (props.$overlap ? 'absolute' : '')};
    top: 0;
    left: 0;
    z-index: ${(props) => (props.$overlap ? 100 : '')};
`;

const Wrapper = styled.section`
    display: inline-block;
    position: relative;
    bottom: ${(props) => (props.$offsetY ? `${props.$offsetY}px` : '0')};
    width: 80px;
    height: 80px;

    section {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #c4cfd4;
        animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

        &:nth-child(1) {
            left: 8px;
            animation-delay: -0.24s;
        }

        &:nth-child(2) {
            left: 32px;
            animation-delay: -0.12s;
        }

        &:nth-child(3) {
            left: 56px;
            animation-delay: 0s;
        }
    }

    @keyframes loading {
        0% {
            top: 8px;
            height: 64px;
        }
        50%,
        100% {
            top: 24px;
            height: 32px;
        }
    }
`;


const Loader = (props) => {
    return (
        <Wrapper $offsetY={props.offsetY}>
            <section></section>
            <section></section>
            <section></section>
        </Wrapper>
    );
};

export default Loader;
